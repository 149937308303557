
import { EnumCols, PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelatorioReceita extends PageBase { 
    service = new RelatorioService();
    item = new Relatorio();
    overlay: boolean = false;
    filter:any = {};

    mounted() { 
        this.filter = this.$route.query;
        this.overlay = true;
        
        this.service.ListarSituacoesContrato(this.filter.empreendimentoId, this.filter.dataSituacaoInicial, this.filter.dataSituacaoFinal, this.filter.dataEmissaoContratoInicial, this.filter.dataEmissaoContratoFinal, this.filter.empresaId, this.filter.situacaoContratoId)
            .then(
                res => { 
                    this.item = res.data;
                },
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{
                this.overlay = false;
            });
    } 
}
 
class Relatorio {
    public numeroContrato:number = 0;
    public sequenciaContrato:number = 0;
    public nomeSituacaoAtualContrato:string =  "";
    public dataSituacaoAtualContrato:string = "";
    public dataEmissaoContrato:string = "";
    public valorTotalFinanciamento:number = 0;
    public empresaContratoNome:string ="";
    public nomeEmpreendimento:string = "";
    public nomeQuadra:string = "";
    public nomeLote:string = "";
    public nomeCliente:string = "";
    public nomeUsuarioResponsavel: string = "";
}
